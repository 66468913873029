import { LocalStorageItem } from '@app/constants';
import { LocalizedMap } from '@app/models';
import { Locale, getDefaultOptions, setDefaultOptions } from 'date-fns';
import { enUS, ru } from 'date-fns/locale';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18n_en from './en.json';
import i18n_ru from './ru.json';

export enum Language {
  RU = 'ru',
  EN = 'en',
}

const DATE_FNS_LOCALES: Record<Language, Locale> = {
  [Language.RU]: ru,
  [Language.EN]: enUS,
};

export const initLocalization = async (): Promise<unknown> => {
  const selectedLang = localStorage.getItem(LocalStorageItem.LANGUAGE) as Language | null;

  setDefaultOptions({ locale: DATE_FNS_LOCALES[selectedLang ?? Language.RU] });

  return i18n.use(initReactI18next).init({
    resources: {
      [Language.RU]: {
        translation: i18n_ru,
      },
      [Language.EN]: {
        translation: i18n_en,
      },
    },
    lng: selectedLang ?? undefined,
    fallbackLng: Language.RU,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
  });
};

export const changeLang = (lang: Language): void => {
  localStorage.setItem(LocalStorageItem.LANGUAGE, lang);
  // i18n.changeLanguage(lang);
  // setDefaultOptions({ locale: DATE_FNS_LOCALES[lang] });
  window.location.reload();
};

export const localizeDayOfWeek = (day: Day, options?: object): string =>
  (getDefaultOptions() as { locale: Locale }).locale.localize?.day(day, options);

export const tMap = (text?: LocalizedMap | string | null): string => {
  if (!text) {
    return '';
  }
  if (typeof text === 'string') {
    return text;
  }
  return text[i18n.language] || Object.values(text)[0] || '';
};
