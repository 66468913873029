import { Button, ButtonStyle, Popover } from '@app/components';
import { SiteMap } from '@app/constants';
import { useEnhancedTranslation } from '@app/hooks';
import { FC, useState } from 'react';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';

import './cookie-popup.scss';

export const CookiePopup: FC = () => {
  const { t, tRoot } = useEnhancedTranslation({ keyPrefix: 'cookieAgreement' });
  const [detailsVisible, setDetailsVisible] = useState(false);
  return (
    <>
      <CookieConsent
        containerClasses="cookies"
        contentClasses="cookies__content"
        buttonWrapperClasses="cookies__buttons"
        buttonClasses="cookies__accept-btn"
        disableStyles
        buttonText={t('accept')}
      >
        <p>{t('title')}</p>
        <Button
          className="cookies__details-btn"
          styling={ButtonStyle.ARROW}
          onClick={() => setDetailsVisible(true)}
          size="small"
        >
          {tRoot('common.seeDetails')}
        </Button>
      </CookieConsent>

      <Popover className="cookies__details-popover" open={detailsVisible} onClose={() => setDetailsVisible(false)}>
        <h2 className="cookies__details-title">{t('about')}</h2>
        <p className="cookies__agreement">{t('agreement')}</p>
        <p className="cookies__policy">
          <Link to={SiteMap.PRIVACY_POLICY} className="cookies__policy-link">
            {t('policyTitle')}
          </Link>
        </p>
        <Button onClick={() => setDetailsVisible(false)} className="cookies__close-btn">
          {tRoot('common.form.ok')}
        </Button>
      </Popover>
    </>
  );
};
