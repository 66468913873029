import {
  DetailedHTMLProps,
  EventHandler,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  SyntheticEvent,
  KeyboardEvent,
} from 'react';

type InteractiveDivProps = {
  onClick?: EventHandler<SyntheticEvent>;
} & Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'onClick'>;

export const InteractiveDiv = forwardRef(function InteractiveDiv(
  { onClick, onKeyDown, role, tabIndex, children, ...restProps }: InteractiveDivProps,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element {
  const handleKeyDown =
    onKeyDown ??
    ((event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        onClick?.(event);
      }
    });

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      ref={ref}
      role={role || 'button'}
      tabIndex={tabIndex ?? 0}
      onClick={onClick}
      onKeyDown={handleKeyDown}
      {...restProps}
    >
      {children}
    </div>
  );
});
