import { ReactComponent as ArrowIcon } from '@app/assets/icons/arrow.svg';
import cx from 'classnames';
import { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import './arrow-link.scss';

type ArrowLinkSize = 'medium' | 'small';

type ArrowLinkProps = PropsWithChildren<
  LinkProps & {
    className?: string;
    to: string;
    externalLink?: boolean;
    size?:
      | ArrowLinkSize
      | {
          text: ArrowLinkSize;
          icon: ArrowLinkSize;
        };
  }
>;

export const ArrowLink: FC<ArrowLinkProps> = ({ className, to, externalLink, children, size = 'medium', ...rest }) => {
  const classes = cx('arrow-link', className, {
    'arrow-link--medium-text': size === 'medium' || (typeof size !== 'string' && size.text === 'medium'),
    'arrow-link--medium-icon': size === 'medium' || (typeof size !== 'string' && size.icon === 'medium'),
    'arrow-link--small-text': size === 'small' || (typeof size !== 'string' && size.text === 'small'),
    'arrow-link--small-icon': size === 'small' || (typeof size !== 'string' && size.icon === 'small'),
  });

  const content = (
    <>
      <span>{children}</span>
      <ArrowIcon className="arrow-link__icon" />
    </>
  );

  if (externalLink) {
    return (
      <a href={to} target="_blank" rel="noopener noreferrer" className={classes} {...rest}>
        {content}
      </a>
    );
  }
  return (
    <Link to={to} className={classes} {...rest}>
      {content}
    </Link>
  );
};
