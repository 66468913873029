import { SiteMap } from '@app/constants';

export const NAVBAR_DATA: { name: string; link: SiteMap }[] = [
  {
    name: 'brands.title',
    link: SiteMap.BRANDS,
  },
  {
    name: 'food.title',
    link: SiteMap.FOOD,
  },
  {
    name: 'services.title',
    link: SiteMap.SERVICES,
  },
  {
    name: 'events.title',
    link: SiteMap.EVENTS,
  },
  {
    name: 'stories.title',
    link: SiteMap.STORIES,
  },
  {
    name: 'about.title',
    link: SiteMap.ABOUT,
  },
  {
    name: 'certificates.title',
    link: SiteMap.CERTIFICATES,
  },
  {
    name: 'contacts.navTitle',
    link: SiteMap.CONTACTS,
  },
];
