import { tMap } from '@app/i18n';
import { KeyPrefix, Namespace, TFunction } from 'i18next';
import { useTranslation, UseTranslationOptions, UseTranslationResponse } from 'react-i18next';
import { DefaultNamespace } from 'react-i18next/TransWithoutContext';

type EnhancedTranslationConfig<N, TKPrefix> = UseTranslationOptions<TKPrefix> & {
  ns?: N | Readonly<N>;
};

type EnhancedTranslationResponse<N extends Namespace, TKPrefix = undefined> = UseTranslationResponse<N, TKPrefix> & {
  tRoot: TFunction<N, TKPrefix>;
  tMap: typeof tMap;
};

export const useEnhancedTranslation = <
  N extends Namespace = DefaultNamespace,
  TKPrefix extends KeyPrefix<N> = undefined,
>({
  ns,
  keyPrefix,
  ...rest
}: EnhancedTranslationConfig<N, TKPrefix>): EnhancedTranslationResponse<N, TKPrefix> => {
  const prefixedTranslation = useTranslation<N, TKPrefix>(ns, {
    keyPrefix,
    ...rest,
  });
  const rootTranslation = useTranslation<N, TKPrefix>(ns, rest);

  if (!keyPrefix?.length) {
    return Object.assign(rootTranslation, {
      tRoot: rootTranslation.t,
      tMap,
    });
  }

  return Object.assign(prefixedTranslation, {
    tRoot: rootTranslation.t,
    tMap,
  });
};
