import { createContext, FC, PropsWithChildren, useState, useMemo } from 'react';

type HeaderContextModel = Partial<{
  isBadgeVisible: boolean;
  setIsBadgeVisible: (isBadgeVisible: boolean) => void;
}>;

export const HeaderContext = createContext<HeaderContextModel>({});

export const HeaderContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isBadgeVisible, setIsBadgeVisible] = useState<boolean>();

  const value = useMemo(() => ({ isBadgeVisible, setIsBadgeVisible }), [isBadgeVisible]);

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>;
};
