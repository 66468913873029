import { colorApi as api } from '../documents/documentsApi';

export const documentsTaggedApi = api.enhanceEndpoints({
  addTagTypes: ['Documents'],
  endpoints: {
    documentsControllerGet: {
      providesTags: ['Documents'],
    },
  },
});
