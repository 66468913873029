/* eslint-disable @typescript-eslint/no-unused-vars */
import { RootState } from '@app/store';
import { loggedOut, setAccessToken } from '@app/store/reducers/authSlice';
import { BaseQueryFn, createApi, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();

const REFRESH_URL = '/api/v1/auth/refresh';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL || '/',
  credentials: 'include',
  // mode: 'cors', // TODO!
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() as RootState).authReducer.access.accessToken;
    if (token) {
      headers.set('authorization', `Bearer ${token}`);
    }

    headers.set('Access-Control-Allow-Origin', '*');

    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions,
) => {
  await mutex.waitForUnlock();
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(REFRESH_URL, api, extraOptions);
        if (refreshResult.data) {
          const { accessToken } = refreshResult.data as { accessToken: string };
          api.dispatch(setAccessToken(accessToken));
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(loggedOut());
        }
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }
  return result;
};

export const connectEmptyApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
