import { useLayoutEffect, useState, useCallback, RefObject } from 'react';
import throttle from 'lodash/throttle';

interface Size {
  width: number;
  height: number;
}

/**
 * @param callback use useCallback when passing! Otherwise it triggers an infinite loop
 */
export const useElementSize = <T extends HTMLElement>(
  ref: RefObject<T>,
  callback?: (entry: DOMRectReadOnly) => void,
): Size => {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    throttle(([entry]: ResizeObserverEntry[]) => {
      setSize({
        width: entry.contentRect.width,
        height: entry.contentRect.height,
      });
      callback?.(entry.contentRect);
    }, 1000),
    [callback],
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return undefined;
    }

    const observer = new ResizeObserver(handleResize);
    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, [handleResize, ref]);

  return size;
};
