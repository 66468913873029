import { ReactComponent as ArrowIcon } from '@app/assets/icons/arrow.svg';
import { useMedia, useScrollDirection } from '@app/hooks';
import cx from 'classnames';
import isString from 'lodash/isString';
import { FC, useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';

import './scroll-up.scss';

type ScrollUpProps = {
  disableScrollUpOn?: (
    | string
    | {
        from: string;
        to: string;
      }
  )[];
};

export const ScrollUp: FC<ScrollUpProps> = ({ disableScrollUpOn }) => {
  const { pathname } = useLocation();
  const { isMobile } = useMedia();
  const lastPathnameRef = useRef<string>();

  const { scrollYOffset } = useScrollDirection();
  const flipped = scrollYOffset > window.innerHeight / 2;

  const onClick = useCallback(
    () =>
      flipped
        ? window.scrollTo({ top: 0, behavior: 'smooth' })
        : window.scrollTo({ top: window.innerHeight, behavior: 'smooth' }),
    [flipped],
  );

  useEffect(() => {
    const isScrollUpDisabled = disableScrollUpOn?.some((path) => {
      if (isString(path)) return pathname.includes(path);
      return pathname.includes(path.to) && lastPathnameRef.current?.includes(path.from);
    });
    lastPathnameRef.current = pathname;
    if (isScrollUpDisabled) return;
    window.scrollTo({ top: 0, behavior: 'auto' });
  }, [disableScrollUpOn, pathname]);

  if (isMobile) {
    return null;
  }

  return createPortal(
    <button type="button" aria-label="Вернуться наверх" className="scroll-up" onClick={onClick}>
      <ArrowIcon
        className={cx('scroll-up__icon', {
          'scroll-up__icon--flipped': flipped,
        })}
      />
    </button>,
    document.body,
  );
};
