import { Overlay } from '@app/components';
import { useOnEscape } from '@app/hooks';
import cx from 'classnames';
import { gsap } from 'gsap';
import { FC, ReactNode, useLayoutEffect, useMemo, useRef } from 'react';

import './drawer.scss';

export enum DrawerPosition {
  LEFT = 'left',
  RIGHT = 'right',
  TOP = 'top',
  BOTTOM = 'bottom',
}

type DrawerProps = {
  className?: string;
  containerEl?: HTMLElement;
  children?: ReactNode;
  opened: boolean;
  position?: DrawerPosition;
  onClickOutside?: () => void;
};

export const Drawer: FC<DrawerProps> = ({
  className,
  containerEl,
  children,
  position = DrawerPosition.RIGHT,
  opened,
  onClickOutside,
}) => {
  const sidebarRef = useRef(null);
  const timelineRef = useRef<gsap.core.Timeline>(gsap.timeline());

  const positionConfig = useMemo(
    () => ({
      [DrawerPosition.LEFT]: { className: 'drawer__sidebar--left', gsapOptions: { x: 0 } },
      [DrawerPosition.BOTTOM]: { className: 'drawer__sidebar--bottom', gsapOptions: { y: 0 } },
      [DrawerPosition.RIGHT]: { className: 'drawer__sidebar--right', gsapOptions: { x: 0 } },
      [DrawerPosition.TOP]: { className: 'drawer__sidebar--top', gsapOptions: { y: 0 } },
    }),
    [],
  );

  useLayoutEffect(() => {
    timelineRef.current = gsap
      .timeline({ paused: true })
      .to(sidebarRef.current, { duration: 0.15, ...positionConfig[position].gsapOptions });
    return () => {
      timelineRef.current?.revert();
      timelineRef.current?.kill();
    };
  }, [position, positionConfig]);

  useLayoutEffect(() => {
    opened ? timelineRef.current?.play() : timelineRef.current?.reverse();
  }, [opened]);

  useOnEscape(onClickOutside);

  return (
    <Overlay onClick={onClickOutside} containerEl={containerEl} className="drawer__overlay" visible={opened}>
      <aside ref={sidebarRef} className={cx('drawer__sidebar', className, positionConfig[position].className)}>
        {children}
      </aside>
    </Overlay>
  );
};
