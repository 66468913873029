import { Language, changeLang } from '@app/i18n';
import i18next from 'i18next';
import identity from 'lodash/identity';
import upperCase from 'lodash/upperCase';
import { Dropdown } from '@app/components';

export const ChangeLangMenu = (): JSX.Element => {
  return (
    <Dropdown<Language>
      className="header__lang"
      options={Object.values(Language)}
      defaultOption={i18next.language as Language}
      onValueChange={changeLang}
      selectId={identity}
      selectValue={upperCase}
    />
  );
};
