import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink, RelativeRoutingType } from 'react-router-dom';

export type NavBarProps = {
  data: {
    name: string;
    link: string;
  }[];
  className?: string;
  listClassName?: string;
  itemClassName?: string;
  relative?: RelativeRoutingType;
};

export const NavBar = ({ data, className, listClassName, itemClassName, relative }: NavBarProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <nav className={cx(className, 'navbar')}>
      <ul className={cx(listClassName, 'navbar__list')}>
        {data.map((item) => (
          <li key={item.name} className="navbar__item">
            <NavLink to={item.link} relative={relative} className={cx(itemClassName, 'navbar__link')}>
              {t(item.name)}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};
