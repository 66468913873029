import { LocalStorageItem } from '@app/constants';
import jwtDecode from 'jwt-decode';

export const cleanPhone = (phone: string): string => {
  return phone.replace(/[^\d]+/g, '');
};

export const saveToken = (token: string): void => {
  const { username } = jwtDecode<{ isRegistred: boolean; username: string }>(token);
  localStorage.setItem(LocalStorageItem.PHONE, username);
};

export const removeToken = (): void => {
  localStorage.removeItem(LocalStorageItem.IS_REGISTERED);
  localStorage.removeItem(LocalStorageItem.PHONE);
};

export const getPhone = (): string => {
  return localStorage.getItem(LocalStorageItem.PHONE) ?? '';
};
