/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { LocalStorageItem } from '@app/constants';
import { Sortable } from '@app/models';
import { parse } from 'date-fns';
import { gsap } from 'gsap';
import chunk from 'lodash/chunk';
import isNil from 'lodash/isNil';
import memoize from 'lodash/memoize';
import omitBy from 'lodash/omitBy';
import times from 'lodash/times';
import uniq from 'lodash/uniq';

export const clean = <T extends object | undefined | null>(obj: T): Partial<T> => {
  return omitBy(obj, isNil) as Partial<T>;
};

export const compareSortable = (first: Sortable, second: Sortable): number => {
  if (!Number.isFinite(first.sortOrder) && !Number.isFinite(second.sortOrder)) {
    return 0;
  }
  if (!Number.isFinite(first.sortOrder) && Number.isFinite(second.sortOrder)) {
    return 1;
  }
  if (Number.isFinite(first.sortOrder) && !Number.isFinite(second.sortOrder)) {
    return -1;
  }
  if (first.sortOrder! < second!.sortOrder!) {
    return 1;
  }
  if (first.sortOrder! > second.sortOrder!) {
    return -1;
  }
  return 0;
};

export const incrementReducer = (state: number): number => {
  return state + 1;
};

export const removeProtocol = (url: string | undefined): string => {
  if (!url) {
    return '';
  }
  return url.replace(/(^\w+:|^)\/\//, '');
};

/** Adds `//` if no protocol specified, if url is already valid, returns it unchanged */
export const repairUrl = (url: string): string => {
  try {
    // eslint-disable-next-line no-new
    new URL(url);
  } catch (error) {
    return `//${url}`;
  }
  return url;
};

export const generateCyrillicAlphabet = (capital = true): string[] => {
  return times(32, (i) => String.fromCharCode(i + (capital ? 1040 : 1072)));
};

export const generateLatinAlphabet = (capital = true): string[] => {
  return times(26, (i) => String.fromCharCode(i + (capital ? 65 : 97)));
};

export const getStubApi = <T>(
  data: T[],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ((arg?: { limit?: number; page?: number } | any) => { data: T[]; totalCount: number; isSuccess: boolean }) => {
  const chunkCached = memoize(chunk, (args?: ArrayLike<unknown> | null) => args?.[1]);
  const empty: T[] = [];
  return (arg) => {
    return {
      data: arg?.limit ? chunkCached(data, arg.limit)[Number.isInteger(arg.page) ? arg.page! : 0] ?? empty : data,
      totalCount: data.length,
      isSuccess: true,
    };
  };
};

type ToggleOptions = {
  scale?: boolean;
  duration?: number;
};

export const openEl = (el: Element | string, options?: ToggleOptions): gsap.core.Tween | undefined => {
  if (!el) return undefined;
  return gsap.to(el, {
    height: 'auto',
    scale: options?.scale ? 1 : undefined,
    duration: options?.duration ?? 0.5,
    ease: 'power1.inOut',
  });
};

export const closeEl = (el: Element | string, options?: ToggleOptions): gsap.core.Tween | undefined => {
  if (!el) return undefined;
  return gsap.to(el, {
    height: 0,
    scale: options?.scale ? 0 : undefined,
    duration: options?.duration ?? 0.5,
    ease: 'power1.inOut',
  });
};

export const toggleEl = (el: Element | string, options?: ToggleOptions): gsap.core.Tween | undefined => {
  if (!el) return undefined;
  return gsap.getProperty(el, 'height') === 0 ? openEl(el, options) : closeEl(el, options);
};

export const getSuggestionItems = (): string[] => {
  const suggestionsStr = window.localStorage.getItem(LocalStorageItem.SEARCH_SUGGESTIONS);
  let suggestions: string[] = [];
  try {
    suggestions = suggestionsStr ? (JSON.parse(suggestionsStr) as string[]) : [];
  } catch {
    window.localStorage.setItem(LocalStorageItem.SEARCH_SUGGESTIONS, '[]');
  }
  return suggestions;
};

export const addSuggestionItem = (item: string): void => {
  const items = getSuggestionItems();
  items.unshift(item);
  const updatedItems = uniq(items).slice(0, 5);
  window.localStorage.setItem(LocalStorageItem.SEARCH_SUGGESTIONS, JSON.stringify(updatedItems));
};

export const ymGoal = (name: string): void => {
  window.ym?.(window.ymKey, 'reachGoal', name);
};

export const parseDateWithoutTimezone = (dateStr: string, format: string): string => {
  const parsedDate = parse(dateStr, format, new Date());
  return new Date(Date.UTC(parsedDate.getFullYear(), parsedDate.getMonth(), parsedDate.getDate())).toISOString();
};

export const getRandomInteger = (min = 0, max = Number.MAX_SAFE_INTEGER): number => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const isDevMode = (): boolean => {
  return process.env.NODE_ENV === 'development';
};

export const omitUrlPathParam = (path: string, depth = 1): string => {
  let result = path;
  times(depth, () => {
    result = path.replace(/\/?.+\//, '/');
  });
  return result;
};
