import { connectEmptyApi as api } from '../connect-empty-api';
import { DocumentControllerFindAllApiResponse } from './model';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    documentsControllerGet: build.query<DocumentControllerFindAllApiResponse, void>({
      query: () => ({
        url: '/api/v1/documents',
      }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as colorApi };

export const { useDocumentsControllerGetQuery } = injectedRtkApi;
