import { connectEmptyApi as api } from '../connect-empty-api';
import { AppSettingsControllerGetApiResponse } from './types';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    appSettingsControllerGet: build.query<AppSettingsControllerGetApiResponse, void>({
      query: () => ({
        url: '/api/v1/appSettings',
      }),
    }),
  }),
  overrideExisting: false,
});

export { injectedRtkApi as colorApi };

export const { useAppSettingsControllerGetQuery } = injectedRtkApi;
