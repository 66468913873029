import { ReactComponent as LogoIcon } from '@app/assets/icons/logo.svg';
import { NavBar } from '@app/components';
import { SiteSearch } from '@app/components/site-search/SiteSearch';
import { LocalStorageItem, SiteMap } from '@app/constants';
import { NAVBAR_DATA } from '@app/data/navbar.data';
import { ScrollDirection, useMedia, useScrollDirection } from '@app/hooks';
import cx from 'classnames';
import { useMemo, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { HeaderContext, HeaderContextProvider } from './header.context';
import { setBadgeFromStorage } from './header.utils';
import { JoinButton } from '../join-button/JoinButton';
import { MobileHeader } from './components/MobileHeader';
import { DesktopHeader } from './components/DesktopHeader';

import './header.scss';

const HeaderContent = (): JSX.Element => {
  const { isDesktop, isPortable } = useMedia();
  const { isBadgeVisible, setIsBadgeVisible } = useContext(HeaderContext);
  const { scrollDirection, scrollYOffset } = useScrollDirection(15);

  useEffect(() => {
    setIsBadgeVisible && setBadgeFromStorage(setIsBadgeVisible);
  }, [setIsBadgeVisible]);

  useEffect(() => {
    localStorage.setItem(LocalStorageItem.BADGE_VISIBLE, isBadgeVisible ? 'true' : 'false');
  }, [isBadgeVisible]);

  const ResponsibleNavbar = useMemo(
    () => (
      <NavBar
        data={isPortable ? NAVBAR_DATA : NAVBAR_DATA.filter((item) => item.link !== SiteMap.CERTIFICATES)}
        className="header__nav"
        listClassName="header__links"
        itemClassName="header__link"
      />
    ),
    [isPortable],
  );

  return (
    <header
      className={cx('header', {
        'header--hidden': scrollDirection === ScrollDirection.DOWN,
        'header--filled': scrollYOffset > window.innerHeight / 8,
      })}
    >
      <Link to={SiteMap.HOME}>
        <LogoIcon className="header__logo" />
      </Link>
      <div style={{ display: isDesktop ? undefined : 'none' }} className="header__nav-n-search">
        {ResponsibleNavbar}
        <SiteSearch />
      </div>
      <div className="header__right">
        {isDesktop && <DesktopHeader />}
        <JoinButton />
        {isPortable && <MobileHeader navbar={ResponsibleNavbar} />}
      </div>
    </header>
  );
};

export const Header = (): JSX.Element => {
  return (
    <HeaderContextProvider>
      <HeaderContent />
    </HeaderContextProvider>
  );
};
