import { ReactComponent as ArrowIcon } from '@app/assets/icons/arrow.svg';
import cx from 'classnames';
import { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react';

import './button.scss';

export enum ButtonStyle {
  OUTLINED,
  ARROW,
  ARROW_OUTLINED,
}

type ButtonSize = 'medium' | 'small';

type ButtonProps = PropsWithChildren<
  ButtonHTMLAttributes<HTMLButtonElement> & {
    className?: string;
    onClick?: () => void;
    invertColors?: boolean;
    arrowDirection?: 'left' | 'right';
    styling?: ButtonStyle;
    size?:
      | ButtonSize
      | {
          text: ButtonSize;
          icon: ButtonSize;
        };
  }
>;

export const Button: FC<ButtonProps> = ({
  className,
  children,
  onClick,
  invertColors,
  disabled,
  type,
  styling = ButtonStyle.OUTLINED,
  arrowDirection = 'right',
  size = 'medium',
  ...rest
}) => {
  const buttonClasses = cx(
    className,
    'button',
    {
      'button--invert-colors': invertColors,
      'button--arrow-left': arrowDirection === 'left',
      'button--disabled': disabled,
      'button--outlined': [ButtonStyle.OUTLINED, ButtonStyle.ARROW_OUTLINED].includes(styling),
    },
    [ButtonStyle.ARROW, ButtonStyle.ARROW_OUTLINED].includes(styling)
      ? {
          'button--medium-text': size === 'medium' || (typeof size !== 'string' && size.text === 'medium'),
          'button--medium-icon': size === 'medium' || (typeof size !== 'string' && size.icon === 'medium'),
          'button--small-text': size === 'small' || (typeof size !== 'string' && size.text === 'small'),
          'button--small-icon': size === 'small' || (typeof size !== 'string' && size.icon === 'small'),
        }
      : undefined,
  );

  return (
    <button
      onClick={onClick}
      // eslint-disable-next-line react/button-has-type
      type={type || 'button'}
      disabled={disabled}
      className={buttonClasses}
      {...rest}
    >
      {children}
      {styling === ButtonStyle.ARROW && <ArrowIcon className="button__icon" />}
    </button>
  );
};
