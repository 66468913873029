import { SiteMap } from '@app/constants';
import { useEnhancedTranslation, useMedia } from '@app/hooks';
import { ymGoal } from '@app/utils';
import { Link } from 'react-router-dom';

import './join-button.scss';

export const JoinButton = (): JSX.Element => {
  const { isDesktop } = useMedia();
  const { t } = useEnhancedTranslation({ keyPrefix: 'header' });

  return (
    <Link
      onClick={() => ymGoal('connecttop')}
      to={isDesktop ? SiteMap.LOYALITY_OVERVIEW : SiteMap.ACCOUNT}
      className="join-button"
    >
      {t('join')}
    </Link>
  );
};
