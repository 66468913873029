import { ReactComponent as SearchIcon } from '@app/assets/icons/search.svg';
import { ReactComponent as CrossIcon } from '@app/assets/icons/cross.svg';
import { ArrowLink } from '@app/components';
import { SiteMap, UrlQueryParam } from '@app/constants';
import { useEnhancedTranslation } from '@app/hooks';
import { useSiteSearch } from './site-search.hook';

import './site-search.scss';

export const SiteSearch = (): JSX.Element => {
  const { query, setQuery, goToSearch } = useSiteSearch();
  const { tRoot } = useEnhancedTranslation({ keyPrefix: 'header' });

  return (
    <>
      <input
        name="main-search"
        className="search text-input"
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={({ key }) => key === 'Enter' && goToSearch()}
        placeholder={tRoot('search.placeholder') || undefined}
      />
      <ArrowLink to={`${SiteMap.SEARCH}?${UrlQueryParam.SEARCH_QUERY}=${query}`} className="search__search-btn" />
    </>
  );
};

type SiteSearchControlProps = {
  className: string;
};

export const SiteSearchControl = ({ className }: SiteSearchControlProps): JSX.Element => {
  const { searchActive, toggleSearch } = useSiteSearch();
  const { t } = useEnhancedTranslation({ keyPrefix: 'header' });

  return (
    // TO DO можно ли убрать отсюда класнейм и добавить его извне?
    <div className={className}>
      {!searchActive && <SearchIcon title={t('search') || undefined} onClick={toggleSearch} />}
      {searchActive && <CrossIcon className="search__control-icon--cross" onClick={toggleSearch} />}
    </div>
  );
};
