import { LocalStorageItem } from '@app/constants';
import { differenceInMinutes } from 'date-fns';

export const setBadgeFromStorage = (setIsBadgeVisible: (isBadgeVisible: boolean) => void): void => {
  const badgeFixedTime = localStorage.getItem(LocalStorageItem.BADGE_FIXED);
  const storageVisible = localStorage.getItem(LocalStorageItem.BADGE_VISIBLE) === 'true';
  if (!badgeFixedTime || (badgeFixedTime && differenceInMinutes(Date.now(), +badgeFixedTime) >= 30)) {
    setIsBadgeVisible(true);
    localStorage.setItem(LocalStorageItem.BADGE_FIXED, Date.now().toString());
    localStorage.setItem(LocalStorageItem.BADGE_VISIBLE, 'true');
  } else {
    setIsBadgeVisible(storageVisible);
  }
};
