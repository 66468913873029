import { useState, useRef, useLayoutEffect, Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SiteMap, UrlQueryParam } from '@app/constants';
import { useMedia, useWindowSize } from '@app/hooks';
import { addSuggestionItem } from '@app/utils';
import { gsap } from 'gsap';

type SiteSearchResponse = {
  query: string;
  setQuery: Dispatch<SetStateAction<string>>;
  toggleSearch: () => void;
  goToSearch: () => void;
  searchActive: boolean;
};

export const useSiteSearch = (): SiteSearchResponse => {
  const { pathname } = useLocation();
  const { isPortable } = useMedia();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [searchActive, setSearchActive] = useState(pathname === SiteMap.SEARCH);
  const [query, setQuery] = useState(searchParams.get('q') || '');
  const windowSize = useWindowSize();
  const timelineRef = useRef<gsap.core.Timeline>();

  const toggleSearch = (): void => {
    setSearchActive(!searchActive);
    searchActive
      ? (() => {
          timelineRef.current?.reverse();
          setQuery('');
        })()
      : timelineRef.current?.play();
  };

  const goToSearch = (): void => {
    addSuggestionItem(query);
    navigate(`${SiteMap.SEARCH}?${UrlQueryParam.SEARCH_QUERY}=${query}`);
  };

  useLayoutEffect(() => {
    if (isPortable) return undefined;
    const searchSelector = '.header__nav-n-search .search';
    const navSelector = '.header__nav-n-search .header__nav';
    timelineRef.current = gsap
      .timeline({
        defaults: { duration: 0.5 },
        reversed: !searchActive,
      })
      .set(searchSelector, { display: 'block' })
      .set(navSelector, { overflow: 'hidden' })
      .to(navSelector, {
        width: 0,
        ease: 'power1.inOut',
        flexShrink: 1,
        flexGrow: 0,
      })
      .to(searchSelector, {
        width: 'auto',
        ease: 'power1.inOut',
        flexGrow: 1,
        flexShrink: 0,
        marginLeft: '5vw',
        marginRight: '5vw',
        paddingLeft: '0.6em',
        paddingRight: '0.6em',
      })
      .to('.search__search-btn', {
        autoAlpha: 1,
        duration: 0.3,
      });
    return () => {
      timelineRef.current?.revert();
      timelineRef.current?.kill();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width, isPortable]);

  return { query, setQuery, toggleSearch, goToSearch, searchActive };
};
