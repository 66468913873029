import { connectEmptyApi as api } from './connect-empty-api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    sendSmsCode: build.mutation<SendSmsCodeApiResponse, SendSmsCodeApiArg>({
      query: (queryArg) => ({ url: '/api/v1/auth/send-sms-code', method: 'POST', body: queryArg.sendSmsCodeRequest }),
    }),
    verifyPhone: build.mutation<VerifyPhoneApiResponse, VerifyPhoneApiArg>({
      query: (queryArg) => ({ url: '/api/v1/auth/verify-phone', method: 'POST', body: queryArg.verifyPhoneDto }),
    }),
    authControllerLogout: build.query<AuthControllerLogoutApiResponse, AuthControllerLogoutApiArg>({
      query: () => ({ url: '/api/v1/auth/logout' }),
    }),
    authControllerRefresh: build.query<AuthControllerRefreshApiResponse, AuthControllerRefreshApiArg>({
      query: () => ({ url: '/api/v1/auth/refresh' }),
    }),
    authControllerProfile: build.query<AuthControllerProfileApiResponse, AuthControllerProfileApiArg>({
      query: () => ({ url: '/api/v1/auth/profile' }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type SendSmsCodeApiResponse = /** status 200 Return single entry. */ AuthAttemptDto;

export type SendSmsCodeErrorResponse = {
  status: number;
  error: string;
  errorType?: 'TrySendSmsCodeError';
};

export type SendSmsCodeApiArg = {
  sendSmsCodeRequest: SendSmsCodeRequestDto;
};

export type VerifyPhoneApiResponse = /** status 200 Return single entry. */ ProfileLoginResponseDto;
export type VerifyPhoneApiArg = {
  verifyPhoneDto: VerifyPhoneRequestDto;
};

export type AuthControllerLogoutApiResponse = unknown;
export type AuthControllerLogoutApiArg = void;
export type AuthControllerRefreshApiResponse = {
  refreshToken: string;
  accessToken: string;
};
export type AuthControllerRefreshApiArg = void;
export type AuthControllerProfileApiResponse = /** status 200 Return single entry. */ AuthProfileResponseDto;
export type AuthControllerProfileApiArg = void;

export type AuthAttemptDto = {
  attemptAuth: {
    _id: string;
    phone: string;
    deviceId: string;
    requestedAt: Date;
    expiresAt: Date;
  };
};
export type ProfileLoginResponseDto = {
  accessToken: string;
  refreshToken: string;
};
export type VerifyPhoneRequestDto = {
  phone: string;
  code: string;
  captcha: string | null;
};

export type SendSmsCodeRequestDto = {
  phone: string;
  captcha: string | null;
};
export type AuthProfileResponseDto = {
  name: string;
};
export const {
  useSendSmsCodeMutation,
  useVerifyPhoneMutation,
  useAuthControllerLogoutQuery,
  useLazyAuthControllerLogoutQuery,
  useAuthControllerRefreshQuery,
  useLazyAuthControllerRefreshQuery,
  useAuthControllerProfileQuery,
  useLazyAuthControllerProfileQuery,
} = injectedRtkApi;
