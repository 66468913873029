import { Badge } from '@app/components';
import { ReactComponent as AccountIcon } from '@app/assets/icons/account.svg';
import { ReactComponent as MapIcon } from '@app/assets/icons/map.svg';
import { SiteSearchControl } from '@app/components/site-search/SiteSearch';
import { useContext } from 'react';
import { SiteMap } from '@app/constants';
import { ymGoal } from '@app/utils';
import { Link } from 'react-router-dom';
import { useEnhancedTranslation } from '@app/hooks';
import { ChangeLangMenu } from './ChangeLangMenu';
import { HeaderContext } from '../header.context';

import '../header.scss';

export const DesktopHeader = (): JSX.Element => {
  const { t } = useEnhancedTranslation({ keyPrefix: 'header' });
  const { isBadgeVisible, setIsBadgeVisible } = useContext(HeaderContext);

  return (
    <div className="header__controls">
      <SiteSearchControl className="header__control" />

      <Link to={SiteMap.MAP} onClick={() => ymGoal('depstolay')} className="header__control">
        <MapIcon title={t('mallMap') || undefined} />
      </Link>
      <Link
        to={SiteMap.ACCOUNT}
        onClick={() => {
          ymGoal('perare');
          setIsBadgeVisible?.(false);
        }}
        className="header__control"
      >
        <AccountIcon title={t('account') || undefined} />
        {isBadgeVisible && <Badge variant="desktop" content={1} />}
      </Link>
      <ChangeLangMenu />
    </div>
  );
};
