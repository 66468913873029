import { useEffect, useState } from 'react';
import vars from '@app/styles/vars.scss';

/** https://usehooks-ts.com/react-hook/use-media-query */

const isMatching = (query: string): boolean => {
  return window?.matchMedia(query).matches ?? false;
};

const useMediaQuery = (query: string): boolean => {
  const [matches, setMatches] = useState<boolean>(isMatching(query));

  const handleChange = (): void => {
    setMatches(isMatching(query));
  };

  useEffect(() => {
    const matchMedia = window.matchMedia(query);
    handleChange();
    matchMedia.addEventListener('change', handleChange);
    return () => matchMedia.removeEventListener('change', handleChange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return matches;
};

export const useMedia = (): {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isPortable: boolean;
  isNotMobile: boolean;
  isSafari: boolean;
} => {
  const isDesktop = useMediaQuery(vars.isDesktop);
  const isTablet = useMediaQuery(vars.isTablet);
  const isMobile = useMediaQuery(vars.isMobile);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  return {
    isDesktop,
    isTablet,
    isMobile,
    isPortable: !isDesktop,
    isNotMobile: !isMobile,
    isSafari,
  };
};
