import { FC, useState } from 'react';
import cx from 'classnames';
import { ReactComponent as CrossIcon } from '@app/assets/icons/cross.svg';
import { ReactComponent as ErrorIcon } from '@app/assets/icons/error.svg';
import { useEnhancedTranslation, useNoInternet } from '@app/hooks';

import './no-internet-popup.scss';

export const NoInternetPopup: FC = () => {
  const { isOnline } = useNoInternet();
  const { t } = useEnhancedTranslation({ keyPrefix: 'noInternetNotification' });

  const [isClosedByUser, setIsClosedByUser] = useState(false);

  return (
    <div className={cx('no-internet', !isOnline && !isClosedByUser && 'show')}>
      <div className="no-internet__info-wrapper">
        <div>
          <ErrorIcon />
        </div>
        <div className="no-internet__info">
          <div className="no-internet__title">{t('title')}</div>
          <div className="no-internet__text">{t('text')}</div>
        </div>
      </div>
      <button type="button" className="no-internet__close-btn" onClick={() => setIsClosedByUser(true)}>
        <CrossIcon />
      </button>
    </div>
  );
};
