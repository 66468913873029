import { ReactComponent as ChevronIcon } from '@app/assets/icons/chevron.svg';
import { openEl } from '@app/utils';
import cx from 'classnames';
import { gsap } from 'gsap';
import { Key, useLayoutEffect, useRef, useState } from 'react';
import { InteractiveDiv } from '../interactive-div';

import './dropdown.scss';

type DropdownProps<T> = {
  className?: string;
  labelClassName?: string;
  defaultOption?: T;
  options: T[];
  label?: string;
  onValueChange: (value: T) => void;
  selectId: (option: T) => Key;
  selectValue: (option: T) => string;
};

export const Dropdown = <T,>({
  options,
  label,
  defaultOption,
  selectValue,
  selectId,
  onValueChange,
  className,
  labelClassName,
}: DropdownProps<T>): JSX.Element => {
  const [opened, setOpened] = useState(false);
  const timelineRef = useRef<gsap.core.Timeline>();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const optionsRef = useRef<HTMLUListElement>(null);
  const [selectedOption, setSelectedOption] = useState<T | undefined>(defaultOption ?? options[0]);

  const onSelect = (option: T): void => {
    setSelectedOption(option);
    onValueChange(option);
  };

  useLayoutEffect(function initTimeline() {
    if (!optionsRef.current) return undefined;
    timelineRef.current = gsap
      .timeline({ paused: true })
      .to(dropdownRef.current, { borderBottomLeftRadius: 0, borderBottomRightRadius: 0, duration: 0.12 })
      .set(optionsRef.current, { borderWidth: 1, opacity: 1 })
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      .add(openEl(optionsRef.current, { duration: 0.3 })!);

    return () => {
      timelineRef.current?.revert();
      timelineRef.current?.kill();
    };
  }, []);

  useLayoutEffect(
    function toggleOptions() {
      if (!optionsRef.current) return;
      opened ? timelineRef.current?.play() : timelineRef.current?.reverse();
    },
    [opened],
  );

  return (
    <figure className="dropdown">
      {label && <figcaption className={cx(labelClassName, 'dropdown__label')}>{label}</figcaption>}
      <InteractiveDiv
        ref={dropdownRef}
        onClick={() => setOpened((prevOpened) => !prevOpened)}
        className={cx(className, 'dropdown__inner')}
      >
        <span>{selectedOption && selectValue(selectedOption)}</span>
        <ChevronIcon
          className={cx('dropdown__icon', {
            'dropdown__icon--flipped': opened,
          })}
        />
        <ul ref={optionsRef} className="dropdown__options">
          {options.map((option) => (
            <li
              key={selectId(option)}
              className={cx('dropdown__option', {
                'dropdown__option--selected': selectedOption && selectId(selectedOption) === selectId(option),
              })}
            >
              <button className="dropdown__option-btn" type="button" onClick={() => onSelect(option)}>
                {selectValue(option)}
              </button>
            </li>
          ))}
        </ul>
      </InteractiveDiv>
    </figure>
  );
};
