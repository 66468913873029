import { useEffect, useState } from 'react';

export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
}

export const useScrollDirection = (
  scrollSpeedDebounce = 10,
): {
  scrollDirection: ScrollDirection | null;
  scrollYOffset: number;
} => {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection | null>(null);
  const [scrollYOffset, setScrollYOffset] = useState<number>(0);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = (): void => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? ScrollDirection.DOWN : ScrollDirection.UP;
      setScrollYOffset(scrollY);
      if (direction !== scrollDirection && Math.abs(scrollY - lastScrollY) > scrollSpeedDebounce) {
        setScrollDirection(direction);
      }
      lastScrollY = Math.max(scrollY, 0);
    };
    window.addEventListener('scroll', updateScrollDirection);
    return () => {
      window.removeEventListener('scroll', updateScrollDirection);
    };
  }, [scrollDirection, scrollSpeedDebounce]);

  return { scrollDirection, scrollYOffset };
};
