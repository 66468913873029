import { connectEmptyApi as api } from './connect-empty-api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    externalControllerFindOne: build.query<ExternalControllerFindOneApiResponse, ExternalControllerFindOneApiArg>({
      query: (queryArg) => ({ url: `/api/v1/external/phone/${queryArg.phone}` }),
    }),
    externalControllerCreate: build.mutation<ExternalControllerCreateApiResponse, ExternalControllerCreateApiArg>({
      query: (queryArg) => ({ url: '/api/v1/external', method: 'POST', body: queryArg.customerDto }),
    }),
    externalControllerPromo: build.mutation<ExternalControllerPromoApiResponse, ExternalControllerPromoApiArg>({
      query: (queryArg) => ({
        url: `/api/v1/external/promo/${queryArg.customerId}`,
        method: 'POST',
        body: queryArg.externalAddToPromoRequest,
      }),
    }),
    externalControllerUpdateOne: build.mutation<
      ExternalControllerUpdateOneApiResponse,
      ExternalControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/api/v1/external/${queryArg.customerId}`,
        method: 'PUT',
        body: queryArg.customerUpdateDto,
      }),
    }),
    externalControllerGetAllBrandsDiscount: build.query<
      ExternalControllerGetAllBrandsDiscountApiResponse,
      ExternalControllerGetAllBrandsDiscountApiArg
    >({
      query: () => ({ url: '/api/v1/external/brandsDiscount' }),
    }),
    externalControllerGetBrandDiscount: build.query<
      ExternalControllerGetBrandDiscountApiResponse,
      ExternalControllerGetBrandDiscountApiArg
    >({
      query: (queryArg) => ({ url: `/api/v1/external/brandsDiscount/${queryArg.promoId}` }),
    }),
    externalControllerBarcode: build.mutation<ExternalControllerBarcodeApiResponse, ExternalControllerBarcodeApiArg>({
      query: (queryArg) => ({ url: '/api/v1/external/barcode', method: 'POST', body: queryArg.barcodeRequestDto }),
    }),
    externalControllerSubscribeEmail: build.mutation<
      ExternalControllerSubscribeEmailApiResponse,
      ExternalControllerSubscribeEmailApiArg
    >({
      query: (queryArg) => ({ url: '/api/v1/external/subscribe', method: 'POST', body: queryArg.emailDto }),
    }),
    externalControllerOsmicardsLink: build.mutation<
      ExternalControllerOsmicardsLinkApiResponse,
      ExternalControllerOsmicardsLinkApiArg
    >({
      query: (queryArg) => ({
        url: '/api/v1/external/osmicards/link',
        method: 'POST',
        body: queryArg.osmicardsLinkRequestDto,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type ExternalControllerFindOneApiResponse = /** status 200 Информация о пользователи */ CustomerResponseDto;
export type ExternalControllerFindOneApiArg = {
  /** Номер телефона */
  phone: string;
};
export type ExternalControllerCreateApiResponse = /** status 201 Запись успешна создана */ CustomerResponseDto;
export type ExternalControllerCreateApiArg = {
  customerDto: CustomerDto;
};
export type ExternalControllerPromoApiResponse = /** status 201 Запись успешна создана */ CustomerResponseDto;
export type ExternalControllerPromoApiArg = {
  /** ID записи пользователя в вашей системе */
  customerId: string;
  externalAddToPromoRequest: ExternalAddToPromoRequest;
};
export type ExternalControllerUpdateOneApiResponse = /** status 200 Операция завершилась успешно */ CustomerResponseDto;
export type ExternalControllerUpdateOneApiArg = {
  /** ID записи пользователя в вашей системе */
  customerId: string;
  customerUpdateDto: CustomerUpdateDto;
};
export type ExternalControllerGetAllBrandsDiscountApiResponse =
  /** status 200 Информация скидках */ ExternaBrandsDiscountDto;
export type ExternalControllerGetAllBrandsDiscountApiArg = void;
export type ExternalControllerGetBrandDiscountApiResponse =
  /** status 200 Информация скидках */ ExternaBrandsDiscountDto;
export type ExternalControllerGetBrandDiscountApiArg = {
  /** promoID */
  promoId: string;
};
export type ExternalControllerBarcodeApiResponse = /** status 200 Информация о пользователи */ BarcodeResponseDto;
export type ExternalControllerBarcodeApiArg = {
  barcodeRequestDto: BarcodeRequestDto;
};
export type ExternalControllerSubscribeEmailApiResponse = /** status 200 Ок */ BarcodeResponseDto;
export type ExternalControllerSubscribeEmailApiArg = {
  emailDto: EmailDto;
};
export type ExternalControllerOsmicardsLinkApiResponse = /** status 200 Ок */ OsmicardsLinkResponseDto;
export type ExternalControllerOsmicardsLinkApiArg = {
  osmicardsLinkRequestDto: OsmicardsLinkRequestDto;
};
export type AvailableDiscountLevel = {
  level: string;
  discount: number;
  minSum: number;
};
export type CustomerResponseDto = {
  firstName: string;
  lastName: string;
  middleName?: string;
  phone: string;
  email?: string;
  birthday?: string;
  _id?: string;
  cardId: string;
  status?: 'active' | 'disabled';
  customerId: string;
  discountLevel: string;
  spendAmount: number;
  createAt?: string;
  updateAt?: string;
  dataDiscountUntill?: string;
  marketingAgreement?: 'SMS' | 'EMAIL' | 'BOTH';
  activePromo?: string | null;
  availableDiscountLevels: AvailableDiscountLevel[];

  /** @deprecated legacy */
  firstname?: string;
  /** @deprecated legacy */
  lastname?: string;
  /** @deprecated legacy */
  dob?: string;
};
export type BadRequestDto = {
  statusCode: number;
  message: object;
  error: string;
};
export type CustomerDto = {
  firstName: string;
  lastName: string;
  phone: string;
  email?: string;
  birthday?: string;
};
export type ExternalAddToPromoRequest = {
  promoName: string;
};
export type CustomerUpdateDto = {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  birthday?: string;
};
export type DiscountLevelDto = {
  level: number;
  discount: number;
  minSum: number;
};
export type ExternaBrandsDiscountDto = {
  promoID: string;
  discountLevel: DiscountLevelDto[];
};
export type BarcodeParamsDto = {
  size: string;
};
export type BarcodeResponseDto = {
  brandName: string;
  title: string;
  price: number;
  discountPrice: number;
  params: BarcodeParamsDto | null;
};
export type BarcodeRequestDto = {
  barcode: string;
  customerId: string;
};
export type EmailDto = {
  email: string;
};
export type OsmicardsLinkResponseDto = {
  link: string;
};
export type OsmicardsLinkRequestDto = {
  cardId: string;
  customerId: string;
  discount: number;
};
export const {
  useExternalControllerFindOneQuery,
  useLazyExternalControllerFindOneQuery,
  useExternalControllerCreateMutation,
  useExternalControllerPromoMutation,
  useExternalControllerUpdateOneMutation,
  useExternalControllerGetAllBrandsDiscountQuery,
  useLazyExternalControllerGetAllBrandsDiscountQuery,
  useExternalControllerGetBrandDiscountQuery,
  useLazyExternalControllerGetBrandDiscountQuery,
  useExternalControllerBarcodeMutation,
  useExternalControllerSubscribeEmailMutation,
  useExternalControllerOsmicardsLinkMutation,
} = injectedRtkApi;
