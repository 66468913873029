/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';

export const useOnEscape = (handler?: () => any): void => {
  useEffect(() => {
    const onEsc = ({ key }: KeyboardEvent): any => key === 'Escape' && handler?.();
    window.addEventListener('keydown', onEsc);
    return () => window.removeEventListener('keydown', onEsc);
  }, [handler]);
};
